<template>
  <div ref="document">
    <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page" :aria-label="`${constData.chouhyouId}-${chouhyou.page}`">
      <div>
        <TemplateNormal :id="constData.chouhyouId + chouhyou.page" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateNormal from '@/assets/svg/common/return_slip/normal.svg';
import { executeTransactSqls, base64Decode, setPaperA4, splitMultiRowString } from '@/assets/js/common';
import { executeTransactSql } from "@/graphql/mutations";
import { ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { listV_HACCHU_DATA_WITH_MEISAI, listV_CENTER_HACCHU_DATA_WITH_MEISAI, getM_SHOKISETTEI, getT_CHOHYO_COUNTER } from '@/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import html2pdf from 'html2pdf.js'
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: 'ReturnSlip',
  /* コンポーネント */
  components: {
    TemplateNormal
  },
  /* パラメータ */
  props:['params'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 6,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '返品伝票',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '210mm',
        height: '295.01mm',
      },
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],

      // デコード・パースした引数
      parsedParams: null,
      //総ページ数
      pageCount: 0,

      // 返品伝票番号
      returnSlipNumber: null,
    }
  },
  created() {
    // ヘッダ・フッタを非表示
    this.$store.commit("setShowNav", false);
  },
  /* マウント */
  mounted() {
    // ローディングON
    this.$store.commit("setLoading", true);

    // 印刷レイアウト設定
    setPaperA4();

    this.fetchData();
  },
  /* 関数群 */
  methods:{
    async fetchData(){
      // 指定された発注データを設定
      await this.setHacchuData();
    },
    /* 発注データ設定 */
    async setHacchuData(){
      // 引数をデコード
      const decodedParams = await base64Decode(this.params);
      // console.log({decodedParams});
      // JSONパース
      this.parsedParams = JSON.parse(decodedParams);
      const isCenter = this.parsedParams.isCenter;
      // console.log({isCenter});

      // WHERE句生成
      let whereOptions = '';
      // 発注データID
      // whereOptions += `AND HACCHU_DATA_ID = ${this.hacchu_data_id} AND DENPYO_KBN = 2`;
      whereOptions += `AND HACCHU_DATA_ID = ${this.parsedParams.hacchu_data_id} AND DENPYO_KBN = 2`;
      // console.log(whereOptions);

      try {
        let result;
        let orderData;
        if(isCenter) {
          result = await API.graphql(graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI,{where_options: whereOptions}));
          orderData = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
        } else {
          result = await API.graphql(graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI,{where_options: whereOptions}));
          orderData = result.data.listV_HACCHU_DATA_WITH_MEISAI;
        }
        console.log({orderData});
        if(orderData && orderData.length > 0){
          // 返品伝票番号が採番される場合はそのまま使用
          let hasReturnSlipNumber = false;
          for (const data of orderData) {
            if (data.HENDEN_NO) {
              hasReturnSlipNumber = true
              this.returnSlipNumber = data.HENDEN_NO;
              break;
            }
          }
          if (!hasReturnSlipNumber) {
            // 返品伝票番号を採番
            await this.getReturnSlipNumber();
          }
          // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
          await this.initListChouhyou(orderData.length);
          // 共通の置換文字列設定
          await this.createReplacementsCommon(orderData);
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(orderData);
          // 作成した置換文字データをSVGファイルに設定
          // await this.setChouhyou();
          const chouhyouSet = await this.setChouhyou();

          if(chouhyouSet) {
            // PDF出力
            const result = await this.exportToPDF();
            // PDF出力に成功した場合フラグを更新
            if (result) {
              // console.log({result});
              await this.updateFlg(orderData);
            }
          }
        }
        // ローディングOFF
        this.$store.commit("setLoading", false);
      } catch (error) {
        console.log({error});
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'setHacchuData', TYPE["0004"], error);
        // ローディングOFF
        this.$store.commit("setLoading", false);
        // アラート出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
      }
    },
    /* */
    async getReturnSlipNumber() {
      console.log('getReturnSlipNumber');
      // メソッド名を定義
      const method_name="getReturnSlipNumber"
      // ログインユーザーデータ
      const user = this.$store.getters.user;
      console.log({user});

      // 発注データテーブルへの登録
      // コード最大値の取得
      let sqlCounterTables;
      // 検索条件
      let condition = {
        BUMON_CD: user.BUMON_CD,
      };
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let getResult = await API.graphql(
          graphqlOperation(getT_CHOHYO_COUNTER, condition)
        );
        console.log(
          "getT_CHOHYO_COUNTER：result = " +
            { getResult } +
            ", condition = " +
            JSON.stringify(condition)
        );

        // 結果判定
        if (getResult.data.getT_CHOHYO_COUNTER) {
          // データを取得できた場合
          // DBから取得した値を設定
          let resultCounterData = getResult.data.getT_CHOHYO_COUNTER;
          console.log({resultCounterData});
          // カウンタが最大値（99999）到達時は、1から採番
          this.returnSlipNumber =
            resultCounterData.HENPIN_DENPYO_NO === 99999
              ? 1
              : resultCounterData.HENPIN_DENPYO_NO + 1;
          sqlCounterTables = `HENPIN_DENPYO_NO = ${this.returnSlipNumber},`;
          console.log(
            "getT_CHOHYO_COUNTER：registerOrderNo = " +
              this.returnSlipNumber +
              ", sqlCounterTables = " +
              sqlCounterTables
          );

          // カウンタテーブルの値を更新
          let sqlList = [];
          sqlList.push(
            `UPDATE T_CHOHYO_COUNTER SET ` +
              sqlCounterTables +
              ` UPDATE_DATE = CURDATE(),` +
              ` UPDATE_USER_NAME = '${user.LOGIN_USER_CD}'`+
              ` WHERE BUMON_CD = '${user.BUMON_CD}';`
          );

          condition = { SQLs: sqlList };
          console.log({condition});

          try {
            // AppSync→AuroraServerless(MySQL)でデータ取得
            let transactionResult = await API.graphql(
              graphqlOperation(executeTransactSql, condition)
            );

            // レスポンスデータを取得できた際の処理
            if (transactionResult) {
              const responseBody = JSON.parse(transactionResult.data.executeTransactSql.body);
              console.log({responseBody});

              // SQL実行でエラーが発生した場合の処理
              if (transactionResult.data.executeTransactSql.statusCode > 200) {
                // レスポンスメッセージ
                // let message = responseBody.message;
                // // エラー内容
                const errorMessage = responseBody.error;
                console.error({ errorMessage });
                console.info("insertTable：エラー" + responseBody);
                // ログ出力(Lamda関数でのSQL実行でステータスエラー)
                addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
                console.info("insertTable：エラー" + responseBody);
                return false;
              } else {
                // SQLが正常に終了した際の処理
                // 何もしない
              }
            } else {
              // レスポンスデータを取得できなかった際の処理
              console.log("insertTable：エラー2 : " + JSON.stringify(transactionResult));
              // ログ出力(Lamda関数でのSQL実行でステータスエラー)
              addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            }
          } catch (error) {
            console.log({error});
            console.log("insertTable : 異常終了 = " + { error });
            // ログ出力(exception)
            addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
            return false;
          }
        } else {
          if (getResult.errors) {
            // データを取得できなかった場合
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getT_CHOHYO_COUNTER(error2)： error = " + { error }
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(productCnt){
      // console.log('initListChouhyou');
      let productCntRemain = productCnt;
      let page = 1;
      while (productCntRemain > this.constData.cntNormal) {
        // 残りの製品件数が最終帳票の件数に収まらない場合、帳票を出力し続ける
        this.listChouhyou.push({page: page, replacements: []});
        productCntRemain -= this.constData.cntNormal;
        page++;
      }
      // 最終ページ
      this.listChouhyou.push({page: page, replacements: []});
      // 総ページ数を保持
      this.pageCount = page;
    },
    /* 共通の置換配列セット */
    async createReplacementsCommon(result){
      // console.log('createReplacementsCommon');
      const now = new Date();
      const today = `${now.getFullYear()}年${now.getMonth()+1}月${now.getDate()}日`;
      for (let index = 0; index < 3; index++) {
        // 返品日付
        this.replacementsCommon.push({key: `%返品日付${index}%`, value: today, textAnchor: 'start', textLength: 215});
        // 返品伝依頼No
        this.replacementsCommon.push({key: `%O${index}%`, value: this.returnSlipNumber ?? '', textAnchor: 'start', textLength: 89});
        // 仕入先名称
        this.replacementsCommon.push({key: `%仕入先${index}%`, value: result[0].SHIIRESAKI_NAME ?? '', textAnchor: 'start', textLength: 538});
      }
      // 得意先名称
      this.replacementsCommon.push({key: `%得意先%`, value: result[0].CSS_TOKUISAKI_NAME ?? '', textAnchor: 'start', textLength: 538});
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result){
      // console.log('createReplacementsPage');
      // console.log({result});
      let productsIndex = 0;
      let whiteFlg = false;
      // console.log(`listChoiuhyou.length = ${ this.listChouhyou.length}`);
      for (let i = 0; i < this.listChouhyou.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];

        // 営業所情報取得
        const officeInfo = await this.getOfficeData();

        /* ヘッダ・フッタ */
        // 営業所情報設定
        await this.setOfficesData(replacements, officeInfo);

        // 帳票毎に設定可能な製品の件数
        let productCntByChouhyou = this.constData.cntNormal;

        // 製品
        for (let j = 0; j < productCntByChouhyou; j++){
          // 納品書（売上計上用）・仮納品書・物品受領書の3パターン出力
          for (let patternIndex = 0; patternIndex < 3; patternIndex++) {
            let addIndex = patternIndex * this.constData.cntNormal;
            // 商品が一つの場合インデックス0・6・12に同じデータが入る
            let sheetIndex = j + addIndex;
            // console.log(`result.length = ${result.length}`);
            // console.log({addIndex});
            // console.log({sheetIndex});
            // console.log({productsIndex});
            if(productsIndex < result.length){
              // 日付
              const dateParts = result[productsIndex].CHUMON_DATE ? result[productsIndex].CHUMON_DATE.split('-') : '';
              replacements.push({key: `%日付${sheetIndex.toString()}%`, value: `${dateParts[0]}年${dateParts[1]}月${dateParts[2]}日`, textAnchor: 'end', textLength: 179});
              // 注番
              replacements.push({key: `%注番${sheetIndex.toString()}%`, value: result[productsIndex].CHUMON_NO ? `${officeInfo.HACCHU_NO}-${result[productsIndex].CHUMON_NO}` : '', textAnchor: 'end', textLength: 178});
              // 型番
              replacements.push({key: `%型番${sheetIndex.toString()}%`, value: result[productsIndex].SHOHIN_NAME ?? '', textAnchor: 'start', textLength: 331});
              // 数量
              replacements.push({key: `%数${sheetIndex.toString()}%`, value: result[productsIndex].SURYO_SU ? result[productsIndex].SURYO_SU.toLocaleString() : '', textAnchor: 'end', textLength: 116});
              // 単価・金額
              if(result[productsIndex].NET_GK && result[productsIndex].NET_GK !== 0) {
                replacements.push({key: `%単${sheetIndex.toString()}%`, value: result[productsIndex].NET_GK.toLocaleString(), textAnchor: 'end', textLength: 130});
                replacements.push({key: `%金額${sheetIndex.toString()}%`, value: (result[productsIndex].SURYO_SU * result[productsIndex].NET_GK).toLocaleString(), textAnchor: 'end', textLength: 148});
              } else {
                replacements.push({key: `%単${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 130});
                replacements.push({key: `%金額${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 148});
              }
              // 返品計上伝票の単価のみお客様卸価格を出力
              if(result[productsIndex].OKYAKUSAMA_OROSHIKAKAKU_GK) {
                replacements.push({key: `%卸${sheetIndex.toString()}%`, value: result[productsIndex].OKYAKUSAMA_OROSHIKAKAKU_GK.toLocaleString(), textAnchor: 'end', textLength: 130});
                replacements.push({key: `%卸金${sheetIndex.toString()}%`, value: (result[productsIndex].SURYO_SU * result[productsIndex].OKYAKUSAMA_OROSHIKAKAKU_GK).toLocaleString(), textAnchor: 'end', textLength: 148});
              } else {
                replacements.push({key: `%卸${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 130});
                replacements.push({key: `%卸金${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 148});
              }

              // 良品・不良品
              let productStatus = result[productsIndex].HENPIN_STATUS_KBN == 0 ? '' : result[productsIndex].HENPIN_STATUS_KBN == 1 ? '良' : '不';
              replacements.push({key: `%J${sheetIndex.toString()}%`, value: productStatus, textAnchor: 'middle', textLength: 96});
              // 備考
              this.setSummary(replacements, sheetIndex, result[productsIndex].BIKO);
            } else {
              // 日付
              replacements.push({key: `%日付${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 179});
              // 注番
              replacements.push({key: `%注番${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 178});
              // 型番
              replacements.push({key: `%型番${sheetIndex.toString()}%`, value: '', textAnchor: 'start', textLength: 331});
              // 数量
              replacements.push({key: `%数${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 116});
              // 単価
              replacements.push({key: `%単${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 130});
              // 金額
              replacements.push({key: `%金額${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 148});
              // 返品計上伝票用単価
              replacements.push({key: `%卸${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 130});
              // 返品計上伝票用金額
              replacements.push({key: `%卸金${sheetIndex.toString()}%`, value: '', textAnchor: 'end', textLength: 148});
              // 良品・不良品
              replacements.push({key: `%J${sheetIndex.toString()}%`, value: '', textAnchor: 'middle', textLength: 96});
              // 備考
              for (let k = 0; k < 2; k++) {
                replacements.push({key: `%備考${sheetIndex.toString()}-${k.toString()}%`, value: '', textAnchor: 'start', textLength: 262});
              }

              // 型番
              if (whiteFlg == true) {
                // 上記以外で以下余白を設定済み
                replacements.push({key: `%型番${j.toString()}%`, value: '', textAnchor: 'start', textLength: 414});
                // console.log(`空白 = ${j}`);
              } else {
                // 以下余白が入る箇所
                if ( i + 1 == this.listChouhyou.length && j + 1 == productCntByChouhyou ) {
                  replacements.push({key: `%型番${j.toString()}%`, value: '以上', textAnchor: 'middle', textLength: 414});
                  // console.log(`以上：j = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
                } else {
                  replacements.push({key: `%型番${j.toString()}%`, value: '以下余白', textAnchor: 'middle', textLength: 414});
                  // console.log(`以下余白 = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
                }
                whiteFlg = true;
              }
            }
            // ページ番号
            replacements.push({key: `%ページ番号${patternIndex.toString()}%`, value: `${this.listChouhyou[i].page} / ${this.pageCount} ページ`, textAnchor: 'start', textLength: 196});
          }
          productsIndex++;
        }
        this.listChouhyou[i].replacements = replacements;
      }
    },
    /* 営業所情報取得 */
    async getOfficeData() {
      // 部門コード
      const user = this.$store.getters.user;
      const bumonCd = user.BUMON_CD;

      // 営業所データ取得
      let result = await API.graphql(graphqlOperation(getM_SHOKISETTEI, {BUMON_CD: bumonCd}));
      // console.log({result});
      const officeInfo = result.data.getM_SHOKISETTEI;
      return officeInfo;
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements, officeInfo) {
      // console.log({bumonData});
      // // TODO データが存在しなかった場合の処理
      // const bumon = bumonData[0];
      // console.log({bumon});

      for (let index = 0; index < 3; index++) {
        // 部門名称
        replacements.push({key: `%部門名${index}%`, value: officeInfo.BUMON_NAME ?? '', textAnchor: 'start', textLength: 190});
      }
    },
    /* 置換文字列に摘要を設定 */
    async setTekiyo(replacements, summary) {
      let arySummary = splitMultiRowString(summary, 52);
      for (let i = 0; i < 5; i++) {
        if (i < arySummary.length) {
          replacements.push({key: `%摘要${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 1566});
        } else {
          replacements.push({key: `%摘要${i.toString()}%`, value: '', textAnchor: 'start', textLength: 1566});
        }
      }
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements, rowNum, summary) {
      let arySummary = splitMultiRowString(summary, 25);
      for (let i = 0; i < 2; i++) {
        if (i < arySummary.length) {
          // 備考
          replacements.push({key: `%備考${rowNum}-${i.toString()}%`, value: arySummary[i], textAnchor: 'start', textLength: 262});
        } else {
          // 備考
          replacements.push({key: `%備考${rowNum}-${i.toString()}%`, value: '', textAnchor: 'start', textLength: 262});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      // console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        // console.log(`this.constData.chouhyouId + this.listChouhyou[i].page = ${this.constData.chouhyouId + this.listChouhyou[i].page}`);
        let svgDoc = document.getElementById(this.constData.chouhyouId + this.listChouhyou[i].page);
        this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
      return true;
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements(node, replacements){
      // console.log('setReplacements');
      for (const textElement of node.getElementsByTagName('text')) {
        for (const child of textElement.children) {
          if(child.tagName == 'tspan'){
            for (const commonReplacement of this.replacementsCommon) {
              if(child.innerHTML.indexOf(commonReplacement.key) != -1){
                this.setTspan(textElement, child, commonReplacement);
                break;
              }
            }
            for(let i = 0; i < replacements.length; i++){
              if(child.innerHTML.indexOf(replacements[i].key) != -1){
                this.setTspan(textElement, child, replacements[i]);
                replacements.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      for (const gElement of node.getElementsByTagName('g')) {
        this.setReplacements(gElement, replacements);
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    async setStamp(tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      // 最大長を設定（最大長を超過する場合、自動で縮小）
      tagTspan.setAttribute('textLength', config.textLength);
      tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      // 座標取得
      let colX = parseFloat(tagTspan.getAttribute('x'));
      // 中央寄せ
      tagTspan.setAttribute('x', colX + config.textLength / 2);
      tagTspan.setAttribute('text-anchor', config.textAnchor);
      // 縦書きを設定（また、文字間隔を狭くする）
      tagTspan.setAttribute('writing-mode', 'tb');
      tagTspan.setAttribute('letter-spacing', -5);
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    /* 帳票をPDFダウンロード */
    exportToPDF() {
      try {
        const pages = Array.from(document.querySelectorAll(`div[aria-label^="${this.constData.chouhyouId}-"]`));

        let worker = html2pdf()
          .set({
            filename: '返品伝票.pdf',
            image: {type: 'jpeg', quality: 0.55},
            html2canvas: { scale: 2 },
          })
          .from(pages[0])

        if (pages.length > 1) {
          // jsPDFインスタンスを生成
          worker = worker.toPdf()

          // 1ページずつPDFをレンダリング
          for (const page of pages.slice(1)) {
            worker = worker
              .get('pdf')
              .then(pdf => {
                pdf.addPage()
              })
              .from(page)
              .toContainer()
              .toCanvas()
              .toPdf()
          }
        }

        worker = worker.save()
        return true;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'exportToPDF', TYPE["0003"], error);
        return false;
      }
    },
    /* 依頼フラグ・返伝NO更新 */
    async updateFlg(orders) {
      // console.log('updateFlg');
      // console.log({orders});

      // 依頼フラグ、返伝NOを更新する
      let updateSQL = '';
      if(this.parsedParams.isCenter) {
        updateSQL += 'UPDATE T_CENTER_HACCHU_MEISAI_DATA SET';
      } else {
        updateSQL += 'UPDATE T_HACCHU_MEISAI_DATA SET';
      }
      updateSQL += ' HENKYAKU_FLG = 1,';
      updateSQL += ` HENDEN_NO = ${this.returnSlipNumber}`;
      updateSQL += ` WHERE HACCHU_DATA_ID = ${orders[0].HACCHU_DATA_ID}`;
      updateSQL += ` AND DENPYO_KBN = 2`;
      // console.log({updateSQL});
      if (!(await executeTransactSqls(this.$route.name, 'updateFlg', updateSQL))) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        return;
      }
    },
  },
}
</script>
<style>
  body
  {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>